import type { BaseInstance } from '@pigello/pigello-matrix';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { getCount } from '../api/get-count';
import { getCountForTimeLine } from '../api/get-count-for-timeline';
import { MAX_BYTES_IN_QUERY_PARAMETERS } from '../constants';
import type { ListResponse } from '../types';
import { identifierMapping } from './data';
import type {
  BuildQuery,
  BuildQueryForSystemEvents,
  StatisticsSystemEvents,
  StatisticsToHandle,
} from './types';

// Utility function to chunk an array into smaller arrays of specified size
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  if (chunkSize <= 0) {
    throw new Error('Chunk size must be greater than 0');
  }
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

/**
 * Splits an array of string IDs into multiple chunks based on byte size.
 *
 * @param ids - Array of IDs to be split
 * @param maxBytes - Maximum byte size per chunk
 * @returns An array where each element is a comma-separated string of IDs
 */
export function chunkIdsByByteSize(
  ids: string[],
  maxBytes = MAX_BYTES_IN_QUERY_PARAMETERS
) {
  if (ids.length === 0) {
    return ids;
  }

  const chunks: string[] = [];
  let currentChunk = '';
  let currentSize = 0;

  const encoder = new TextEncoder();

  for (const id of ids) {
    // Add comma if currentChunk is not empty
    const separator = currentChunk ? ',' : '';
    const separatorSize = encoder.encode(separator).length;
    const idSize = encoder.encode(id).length;

    // Check combined size before committing to the current chunk
    if (currentSize + separatorSize + idSize > maxBytes) {
      chunks.push(currentChunk);
      currentChunk = id;
      currentSize = idSize;
    } else {
      currentChunk += separator + id;
      currentSize += separatorSize + idSize;
    }
  }

  // Push leftover chunk if it exists
  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
}
// Utility function to chunk IDs
export function chunkIds(ids: Set<string>, chunkSize: number = 40): string[][] {
  if (chunkSize <= 0) {
    throw new Error('Chunk size must be greater than 0');
  }
  const chunkedIds: string[][] = [];
  Array.from(ids).forEach((id, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!chunkedIds[chunkIndex]) {
      chunkedIds[chunkIndex] = [];
    }
    chunkedIds[chunkIndex].push(id);
  });
  return chunkedIds;
}

// Determines if an object only contains an 'id' field
export const isThin = (obj: unknown): obj is { id: string } => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    Object.keys(obj).length === 1 &&
    'id' in obj
  );
};

/**
 * Extracts nested IDs from a list of instances based on specified fields.
 *
 * @param list - The main query data list.
 * @param filteredNested - The fields to filter and extract IDs from.
 * @param fetchAllManyRelations - Flag to determine if all many relations should be fetched.
 * @returns A Map where each key is a field name and the value is an array of unique IDs.
 */
export const extractNestedIds = <TInstance>(
  list: TInstance[] | undefined,
  filteredNested: (keyof TInstance)[],
  fetchAllManyRelations: boolean
): Map<keyof TInstance, string[]> => {
  const idsMap = new Map<keyof TInstance, string[]>();

  if (!list) {
    return idsMap;
  }

  for (const instance of list) {
    for (const fieldName of filteredNested) {
      const related = instance[fieldName];
      if (Array.isArray(related)) {
        if (fetchAllManyRelations) {
          for (const item of related) {
            if (isThin(item)) {
              if (!idsMap.has(fieldName)) {
                idsMap.set(fieldName, []);
              }
              idsMap.get(fieldName)?.push(item.id);
            }
          }
        } else {
          const firstItem = related[0];
          if (firstItem && isThin(firstItem)) {
            if (!idsMap.has(fieldName)) {
              idsMap.set(fieldName, []);
            }
            idsMap.get(fieldName)?.push(firstItem.id);
          }
        }
      } else if (isThin(related)) {
        if (!idsMap.has(fieldName)) {
          idsMap.set(fieldName, []);
        }
        idsMap.get(fieldName)?.push(related.id);
      }
    }
  }

  // Remove duplicates
  for (const [key, idsList] of idsMap.entries()) {
    idsMap.set(key, Array.from(new Set(idsList)));
  }

  return idsMap;
};

export function collectNestedIdsSingleInstance<TInstance>(
  data: TInstance | undefined,
  filteredNested: (keyof TInstance)[]
): Map<keyof TInstance, string[]> {
  const ids = new Map<keyof TInstance, string[]>();

  if (!data) {
    return ids;
  }

  for (const fieldName of filteredNested) {
    const baseInstance = data[fieldName] as
      | BaseInstance
      | BaseInstance[]
      | undefined;

    if (Array.isArray(baseInstance)) {
      for (const item of baseInstance) {
        if (isThin(item)) {
          if (!ids.has(fieldName)) {
            ids.set(fieldName, [item.id]);
          } else {
            ids.get(fieldName)?.push(item.id);
          }
        }
      }
    } else if (isThin(baseInstance)) {
      if (!ids.has(fieldName)) {
        ids.set(fieldName, [baseInstance.id]);
      } else {
        ids.get(fieldName)?.push(baseInstance.id);
      }
    }
  }

  return ids;
}

export function mergeDataWithNested<TInstance extends BaseInstance>(
  mainData: ListResponse<TInstance>,
  aggregatedNestedData: Map<keyof TInstance, Map<string, TInstance>>,
  filteredNested: (keyof TInstance)[]
): ListResponse<TInstance> {
  return {
    list: mainData?.list?.map((instance) => {
      for (const fieldName of filteredNested) {
        const thinData = instance[fieldName];
        const instanceDataMap = aggregatedNestedData.get(fieldName);

        if (Array.isArray(thinData)) {
          instance[fieldName] = thinData.map((item) => {
            if (isThin(item) && instanceDataMap) {
              const relatedData = instanceDataMap.get(item.id);
              return relatedData ? relatedData : item;
            }
            return item;
          }) as TInstance[typeof fieldName];
        } else if (isThin(thinData) && instanceDataMap) {
          const relatedData = instanceDataMap.get(thinData.id);
          instance[fieldName] = relatedData
            ? (relatedData as typeof thinData)
            : thinData;
        }
      }
      return instance;
    }),
    meta: mainData.meta,
  };
}

export function aggregateNestedData<TInstance extends BaseInstance>(
  nestedQueries: UseQueryResult<
    {
      fieldName: keyof TInstance;
      data: TInstance[];
    },
    Error
  >[]
): Map<keyof TInstance, Map<string, TInstance>> {
  const nestedDataMap = new Map<keyof TInstance, Map<string, TInstance>>();

  for (const query of nestedQueries) {
    if (query.data) {
      const { fieldName, data } = query.data;

      if (!nestedDataMap.has(fieldName)) {
        nestedDataMap.set(fieldName, new Map());
      }

      const fieldMap = nestedDataMap.get(fieldName)!;

      for (const item of data) {
        fieldMap.set(item.id, item);
      }
    }
  }

  return nestedDataMap;
}

// Make sure the identifier is valid - should look like this: to_handle.organization_user.invite_pending
export const stringSchema = (identifiers: string[]) => {
  for (const identifier of identifiers) {
    const mapping = get(identifierMapping, identifier, {});
    const modelName = get(mapping, 'modelName');
    const group_modelNames = get(mapping, 'group_modelNames');
    if (!modelName && !group_modelNames) return identifier;
  }
  return true;
};

export function buildQueryForStatistics({
  modelName,
  identifier,
  contentType,
  filters,
  noFilterReturn,
  plainUrlReturn,
  ...queryOptions
}: BuildQuery) {
  return {
    queryKey: [
      modelName,
      identifier,
      contentType,
      filters,
      noFilterReturn,
      plainUrlReturn,
    ],
    queryFn: async ({ signal }) => {
      const count = await getCount({
        overrideUrl: contentType,
        modelName,
        filters,
        signal,
      });
      return {
        identifier,
        filters: noFilterReturn ? {} : filters,
        plainUrlReturn,
        modelName,
        count,
      };
    },
    ...queryOptions,
  } as UseQueryOptions<StatisticsToHandle>;
}

export function buildQueryForStatisticsSystemEvents({
  modelName,
  identifier,
  contentType,
  filters,
  noFilterReturn,
  statisticsOptions,
  ...queryOptions
}: BuildQueryForSystemEvents) {
  return {
    queryKey: [
      modelName,
      identifier,
      contentType,
      filters,
      statisticsOptions,
      noFilterReturn,
    ],
    queryFn: async ({ signal }) => {
      const { count, debt_timeline } = await getCountForTimeLine({
        overrideUrl: contentType,
        statisticsOptions,
        modelName,
        filters,
        signal,
      });
      return {
        identifier,
        filters: noFilterReturn ? {} : filters,
        modelName,
        count,
        timeline: debt_timeline?.timeline,
      };
    },
    ...queryOptions,
  } as UseQueryOptions<StatisticsSystemEvents>;
}

export type DateRange = 'day' | 'week' | 'month' | 'year' | null;

export type DateCategory = 'pending' | 'done';

type DateRangeReturn = {
  startDate: DateTime;
  endDate: DateTime;
};

export function getDateRange(
  range: DateRange,
  dateCategory: DateCategory
): DateRangeReturn | undefined {
  const isPending = dateCategory === 'pending';
  switch (range) {
    case 'day':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ days: 1 }),
          }
        : {
            startDate: DateTime.now(),
            endDate: DateTime.now(),
          };
    case 'week':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ days: 7 }),
          }
        : {
            startDate: DateTime.now().startOf('week'),
            endDate: DateTime.now(),
          };
    case 'month':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ months: 1 }),
          }
        : {
            startDate: DateTime.now().startOf('month'),
            endDate: DateTime.now(),
          };
    case 'year':
      return isPending
        ? {
            startDate: DateTime.now().plus({ days: 1 }),
            endDate: DateTime.now().plus({ years: 1 }),
          }
        : {
            startDate: DateTime.now().startOf('year'),
            endDate: DateTime.now(),
          };
  }

  return undefined;
}
