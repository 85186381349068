import type { JsonFilter } from '@/hooks/use-table-filter/types';
import type { ContentType, ModelName } from '@pigello/pigello-matrix';
import type { DateCategory } from '../utils';
import { systemEventsIdentifierData } from './system-events';
import { toHandleIdentifierData } from './to-handle';

export type Identifier = `${string}.${string}.${string}`;

type StatisticsOption = 'debt_timeline';

export type IdentifierInfo = {
  modelName?: ModelName;
  overrideReturnModelName?: ModelName;
  group_modelNames?: ModelName[];
  group_content_types?: ContentType;
  content_type?: ContentType;
  filters?: JsonFilter;
  noFilterReturn?: boolean;
  plainUrlReturn?: boolean;
  timeline_attributes?: { [key in DateCategory]?: string[] };
  statistics_options?: Array<StatisticsOption>;
};

export type IdentifierMapping = {
  [category: string]: {
    [sub_category: string]: {
      [identifier: string]: IdentifierInfo;
    };
  };
};

export const identifierMapping: IdentifierMapping = {
  to_handle: toHandleIdentifierData,
  key_indexes: toHandleIdentifierData,
  system_events: systemEventsIdentifierData,
};
