'use client';

import { useLogout } from '@/hooks/useLogout';
import { DEFAULT_STALE_TIME } from '@/requests/constants';
import {
  isServer,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

declare global {
  interface Window {
    queryClient?: QueryClient;
  }
}

export const NO_RETRY_CODES = [401, 402, 403, 404, 405, 429];

function makeQueryClient(logout?: () => void) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: DEFAULT_STALE_TIME,
        retry(failureCount, error) {
          if (
            'status' in error &&
            !!error?.status &&
            typeof error.status === 'number' &&
            NO_RETRY_CODES.includes(error.status)
          ) {
            return false;
          }
          return failureCount < 3;
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        if ('status' in error && error?.status === 401) {
          logout?.();
        }
      },
    }),
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient(logout?: () => void) {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient(logout);
    return browserQueryClient;
  }
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const { clearUserData } = useLogout();
  function logout() {
    clear();
    clearUserData();
  }

  function clear() {
    queryClient.cancelQueries();
    queryClient.clear();
  }

  const queryClient = getQueryClient(logout);
  if (typeof window !== 'undefined' && !window.queryClient) {
    window.queryClient = queryClient;
  }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools position='bottom' initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
